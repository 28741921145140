<template>
  <div class="container">
    <div class="columns level">
      <div class="column level-items has-text-centered">
        <h1 class="title is-centered">Game does not exist.</h1>
        <h3><i class="far fa-exclamation-square"></i></h3>
      </div>  
    </div> 
  </div>
</template>

<script>
export default {
  name: 'GameDoesNotExist'
}
</script>

<style>

</style>